import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppIcon from './app-icon';

const clsPrefix = 'app-more-icon';

export const MORE_ICON_PROPS = {
  name: 'moreVertical',
  type: 'feather',
};

export const MORE_ICON_HORIZONTAL_PROPS = {
  name: 'ellipsis',
  type: 'antd',
};

export const MORE_ICON_LOADING_PROPS = {
  name: 'loading',
  type: 'antd',
};

const AppMoreIcon = ({ className, loading, variant, ...props }) => {
  let iconProps = {};
  if (loading) {
    iconProps = MORE_ICON_LOADING_PROPS;
  } else {
    iconProps =
      variant === 'vertical' ? MORE_ICON_PROPS : MORE_ICON_HORIZONTAL_PROPS;
  }
  return (
    <AppIcon
      {...props}
      className={classNames(clsPrefix, className)}
      {...iconProps}
    />
  );
};

AppMoreIcon.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
};

AppMoreIcon.defaultProps = {
  variant: 'vertical',
};

export default AppMoreIcon;
