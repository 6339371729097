import AppIconButton from './app-icon-button';
import React, { FC } from 'react';
import classNames from 'classnames';
import { MORE_ICON_LOADING_PROPS, MORE_ICON_PROPS } from './app-more-icon';
import type { AppIconButtonProps } from './app-icon-button';

const clsPrefix = 'app-more-icon-button';

export type AppMoreIconButtonProps = AppIconButtonProps & {
  className?: string;
  loading?: boolean;
  onClick?: (...params: any) => any;
};

const AppMoreIconButton: FC<AppMoreIconButtonProps> = ({ className, loading, onClick, ...appIconButtonprops }) => {
  return <AppIconButton
    {...appIconButtonprops}
    className={classNames(clsPrefix, className)}
    name={loading ? MORE_ICON_LOADING_PROPS.name : MORE_ICON_PROPS.name}
    iconType={(loading ? MORE_ICON_LOADING_PROPS.type : MORE_ICON_PROPS.type) as AppIconButtonProps['iconType']}
    onClick={!loading ? onClick : undefined}
  />;
}

export default AppMoreIconButton;
