

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Badge, Tag } from 'antd';
import { getColor } from 'src/utils/get-color';

const clsPrefix = 'app-label';

export const types = {
  tag: 'tag',
  badge: 'badge',
};

export const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
};

const COLORS = {
  ...window.Glide.CONSTANTS.LABEL_COLORS,
};

// First value is color hex, second value (if present) is font color (default font color is #fff)
export const COLOR_DEFINITIONS = {
  [COLORS.RED]: [
    getColor('avatars-red-color'),
    getColor('avatars-text-red-color'),
  ],
  [COLORS.LIGHT_RED]: ['#FFF1F0', '#BF2925'], // red-1
  [COLORS.GREEN]: [getColor('green-7'), getColor('green-9')],
  [COLORS.LIGHT_GREEN]: [getColor('green-0'), '#7BA924'],
  [COLORS.YELLOW]: [
    getColor('avatars-yellow-color'),
    getColor('avatars-text-yellow-color'),
  ],
  [COLORS.BLUE]: [getColor('blue-6')], // blue-6
  [COLORS.LIGHT_BLUE]: [getColor('app-tertiary-color')],
  [COLORS.LIGHT_PURPLE]: ['#FFF0FC', '#A5267B'],
  [COLORS.LIGHT_CYAN]: [getColor('gray-0'), getColor('app-tertiary-color')],
  [COLORS.PRIMARY]: [
    getColor('avatars-primary-color'),
    getColor('avatars-text-primary-color'),
  ],
  [COLORS.SECONDARY]: [getColor('app-secondary-color')],
  [COLORS.TERTIARY]: [getColor('green-5')],
  [COLORS.GRAY]: [getColor('gray-1'), '#555'],
  [COLORS.DARKER_GRAY]: ['#555', getColor('gray-1')],
  [COLORS.BLACK]: ['#000'],
  [COLORS.WHITE]: [getColor('white-color')],
  undefined: [getColor('gray-1'), '#555'],
};

export const getColorHex = (color) => {
  const colorDef = COLOR_DEFINITIONS && COLOR_DEFINITIONS[color];
  if (colorDef) {
    return colorDef[0];
  }
  return (color || '').toLowerCase();
};

export const getColorFromHex = (hexColor) => {
  return (
    Object.keys(COLOR_DEFINITIONS).find((c) => {
      return COLOR_DEFINITIONS[c][0].toLowerCase() === hexColor.toLowerCase();
    }) || undefined
  );
};

export const getFontColorHex = (color) => {
  const colorDef = COLOR_DEFINITIONS[color];
  return colorDef && colorDef[1] ? colorDef[1] : getColor('white-color');
};

const AppLabel = ({
  className,
  children,
  color,
  fontColor,
  onClick,
  type,
  size,
  ghost,
  whiteBg,
  rounded,
  noHover,
  borderless,
  darkHover,
  ...otherProps
}) => {
  const Comp = {
    [types.tag]: Tag,
    [types.badge]: Badge,
  }[type];

  const text = {
    [types.badge]: children,
  }[type];

  const content = {
    [types.tag]: children,
  }[type];

  if (ghost && type !== types.tag) {
    throw new Error('ghost is only available for AppLabel of type tag.');
  }

  const computedColor = getColorHex(color);

  return (
    <Comp
      {...otherProps}
      className={classNames(clsPrefix, `${clsPrefix}--${size}`, className, {
        [`${clsPrefix}--tag`]: type === types.tag,
        [`${clsPrefix}--badge`]: type === types.badge,
        [`${clsPrefix}--ghost`]: !!ghost,
        [`${clsPrefix}--ghost-white-bg`]: !!ghost && !!whiteBg,
        [`${clsPrefix}--rounded`]: !!rounded,
        [`${clsPrefix}--no-hover`]: !!noHover,
        [`${clsPrefix}--borderless`]: !!borderless,
        [`${clsPrefix}--dark-hover`]: !!darkHover,
      })}
      color={computedColor}
      text={text}
      style={{
        ...(otherProps.style || {}),
        color:
          fontColor ||
          (ghost ? computedColor : undefined) ||
          (type === types.tag ? getFontColorHex(color) : undefined),
        border: ghost ? `1px solid ${computedColor}` : undefined,
      }}
      {...(onClick !== undefined
        ? {
            onClick,
          }
        : {})}
    >
      {content}
    </Comp>
  );
};
AppLabel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  onClick: PropTypes.func,
  ghost: PropTypes.bool,
  whiteBg: PropTypes.bool,
  rounded: PropTypes.bool,
  noHover: PropTypes.bool,
  borderless: PropTypes.bool,
  darkHover: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(types)),
  size: PropTypes.oneOf(Object.values(sizes)),
};
AppLabel.defaultProps = {
  type: types.tag,
  size: sizes.medium,
};
AppLabel.COLORS = COLORS;
AppLabel.COLOR_DEFINITIONS = COLOR_DEFINITIONS;
AppLabel.getColorHex = getColorHex;
AppLabel.getFontColorHex = getFontColorHex;

export default AppLabel;
